import React from "react";
import Helmet from "react-helmet";

import Section from "$ui/section";
import Heading from "$ui/heading";

const TermsAndConditions = () => (
  <Section className="pt-32 max-w-6xl mx-auto">
    <Helmet>
      <title>TheCRO &mdash; Terms & Conditions</title>
    </Helmet>

    <Heading>Terms & Conditions</Heading>
    <h2 className="text-xl font-bold mt-6">Terms of service</h2>
    <article className="mt-10 space-y-10 leading-relaxed">
      <p>
        Agreement between user and{" "}
        <a
          className="underline text-blue-500"
          href="https://voxya.com/"
          rel="nonreferrer"
          target="_blank"
        >
          Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation)
        </a>
      </p>

      <p>
        Welcome to Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation). The Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) website (the "Site") is comprised of various web pages
        operated by Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation)organization. Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) is offered to you conditioned on your acceptance
        without modification of the terms, conditions, and notices contained
        herein (the "Terms"). Your use of Upbhokta Adhikar Sangthan (CRO -
        Consumer rights organisation) constitutes your agreement to all such
        Terms. Please read them carefully, and keep a copy of them for your
        reference
      </p>

      <p>
        By using Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation)
        (the "Site"), you agree to follow and be bound by these Terms and
        Conditions (the "Terms and Conditions") and agree to comply with all
        applicable laws and regulations. In these Terms and Conditions, the
        words "you" and "your" refer to each customer or Site visitor, "we", us"
        and "our" refer to Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) and "Services" refers to all services provided by us.
      </p>
      <p>
        If at any time you find these Terms and Conditions unacceptable or if
        you do not agree to these Terms and Conditions, please do not use this
        Site. We may revise these Terms and Conditions at any time without
        notice to you. It is your responsibility to review these Terms and
        Conditions periodically.
      </p>
      <p>
        You affirm that you are more than 18 years of age and are fully able and
        competent to enter into the terms, conditions, obligations,
        affirmations, representations, and warranties set forth in these Terms
        and Conditions.
      </p>
      <p>
        This Site is not intended to create any attorney-client relationship,
        and your use of Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) does not and will not create an attorney-client
        relationship between you and Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation).
      </p>
      <h2 className="text-xl font-bold mt-6">Privacy</h2>
      <p>
        Your use of Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) is subject to Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation)'s privacy policy. Please review our Privacy policy,
        which also governs the Site and informs users of our data collection
        practices.
      </p>
      <h2 className="text-xl font-bold mt-6">Copyright & Trademarks</h2>
      <p>
        All trademarks, service marks, trade names, product names, company names
        and logos appearing on the site are the property of their respective
        owners.
      </p>
      <h2 className="text-xl font-bold mt-6">Electronic Communications</h2>
      <p>
        Visiting Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation)
        or posting content on Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) public forums and website constitutes electronic
        communications. You consent to receive electronic communications and you
        agree that all agreements, notices, disclosures and other communications
        that we provide to you electronically, via and on the Site, satisfy any
        legal requirement that such communications be in writing.
      </p>
      <h2 className="text-xl font-bold mt-6">Your Account</h2>
      <p>
        If you use this site, you are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer, and you agree to accept responsibility for all
        activities that occur under your account or password. You may not assign
        or otherwise transfer your account to any other person or entity. You
        acknowledge that Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) is not responsible for third party access to your account
        that results from theft or misappropriation of your account. Upbhokta
        Adhikar Sangthan (CRO - Consumer rights organisation) and its associates
        reserve the right to refuse or cancel service, terminate accounts, or
        remove or edit content in our sole discretion.
      </p>
      <p>
        Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation) does not
        knowingly collect, either online or offline, personal information from
        persons under the age of eighteen. If you are under 18, you may use
        Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation) only with
        permission of a parent or guardian.
      </p>
      <p>
        Reviews, Comments, Communications, and Other Content at various
        locations on the Site, Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) may permit visitors to post reviews, comments, and other
        content (the "User Content"). Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) is not the publisher or author of such User
        Content. It is a passive service for storage and dissemination of the
        ideas and opinions that Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) members may choose to post and distribute as User Content.
        Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation) does not
        screen works before they are posted, and no prior approval is required
        for posting. Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) disclaims all copyright and ownership in such works and
        all responsibility for them. Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) has the right, in our sole and absolute discretion,
        to (i) edit, redact or otherwise change any User Content, (ii)
        re-categorize any User Content to place it in a more appropriate
        location or (iii) pre-screen or delete any User Content that is
        determined to be inappropriate or otherwise in violation of these Terms
        and Conditions, including but not limited to User Content containing
        offensive language and advertisements. Upbhokta Adhikar Sangthan (CRO -
        Consumer rights organisation) reserves the right to refuse service to
        anyone and to cancel user access at any time
      </p>
      <p>
        Responsibility for User Content: You are legally responsible for any
        User Content you post on any Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) service
      </p>
      <p>
        You are solely responsible for honouring the rights of others, including
        intellectual-property rights (copyright, patent and trademark), the
        right to privacy and the right not to be libelled or slandered. Your
        access to the postings that users have posted as User Content is for
        your personal use only. If you want to redistribute postings you find as
        User Content, it is your responsibility to obtain permission from the
        poster (and any other person with rights in such work).
      </p>
      <h2 className="text-xl font-bold mt-6">
        Links to Third party sites/services
      </h2>
      <p>
        Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation) may
        contain links to other websites ("Linked Sites"). The Linked Sites are
        not under the control of Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) and Upbhokta Adhikar Sangthan (CRO - Consumer
        rights organisation) is not responsible for the contents of any Linked
        Site, including without limitation any link contained in a Linked Site,
        or any changes or updates to a Linked Site. Upbhokta Adhikar Sangthan
        (CRO - Consumer rights organisation) is providing these links to you
        only as a convenience, and the inclusion of any link does not imply
        endorsement by Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) of the site or any association with its operators.
      </p>
      <p>
        Certain services made available via Upbhokta Adhikar Sangthan (CRO -
        Consumer rights organisation) are delivered by third party sites and
        organizations. By using any product, service or functionality
        originating from the Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) domain, you hereby acknowledge and consent that Upbhokta
        Adhikar Sangthan (CRO - Consumer rights organisation) may share such
        information and data with any third party with whom Upbhokta Adhikar
        Sangthan (CRO - Consumer rights organisation) has a contractual
        relationship to provide the requested product, service or functionality
        on behalf of Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation) users and customers.
      </p>
      <h2 className="text-xl font-bold mt-6">
        No unlawful or prohibited use/Intellectual Property
      </h2>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use Upbhokta Adhikar Sangthan (CRO - Consumer rights
        organisation)strictly in accordance with these terms of use. As a
        condition of your use of the Site, you warrant to Upbhokta Adhikar
        Sangthan (CRO - Consumer rights organisation) that you will not use the
        Site for any purpose that is unlawful or prohibited by these Terms. You
        may not use the Site in any manner which could damage, disable,
        overburden, or impair the Site or interfere with any other party's use
        and enjoyment of the Site. You may not obtain or attempt to obtain any
        materials or information through any means not intentionally made
        available or provided for through the Site.
      </p>
      <p>
        All content included as part of the Service, such as text, graphics,
        logos, images, as well as the compilation thereof, and any software used
        on the Site, is the property of Upbhokta Adhikar Sangthan (CRO -
        Consumer rights organisation) or its suppliers and protected by
        copyright and other laws that protect intellectual property and
        proprietary rights. You agree to observe and abide by all copyright and
        other proprietary notices, legends or other restrictions contained in
        any such content and will not make any changes there to.
      </p>
      <p>
        You will not modify, publish, transmit, reverse engineer, participate in
        the transfer or sale, create derivative works, or in any way exploit any
        of the content, in whole or in part, found on the Site. Upbhokta Adhikar
        Sangthan (CRO - Consumer rights organisation) content is not for resale.
        Your use of the Site does not entitle you to make any unauthorized use
        of any protected content, and in particular you will not delete or alter
        any proprietary rights or attribution notices in any content. You will
        use protected content solely for your personal use, and will make no
        other use of the content without the express written permission of
        Upbhokta Adhikar Sangthan (CRO - Consumer rights organisation) and the
        copyright owner. You agree that you do not acquire any ownership rights
        in any protected content. We do not grant you any licenses, express or
        implied, to the intellectual property of Upbhokta Adhikar Sangthan (CRO
        - Consumer rights organisation) or our licensors except as expressly
        authorized by these Terms.
      </p>
    </article>
  </Section>
);

export default TermsAndConditions;
